#color-picker-input:hover, #color-picker-input:active, #color-picker-input:focus {
    outline-style: none !important;
    border: 1px solid black !important;
}

#color-picker-input {
    border: 1px solid lightgray !important;
    outline-style: none !important;
}

@keyframes text-scroll2 {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}
@keyframes text-scroll {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}